














import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    size: String,
    color: String,
  },
  setup(props) {
    const imageSize = computed(() => {
      if (props.size) {
        if (props.size === "big") {
          return 24;
        }
      } else {
        return 16;
      }
    });

    return { imageSize };
  },
});
