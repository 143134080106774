














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  emits: ["close"],
});
