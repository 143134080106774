import { getCurrentInstance } from "@vue/composition-api";

export const useI18n = () => {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error("Cannot find vue-i18n");
  }

  const i18n = vm.$i18n;

  return {
    locale: i18n.locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
  };
};
