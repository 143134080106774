
















import { defineComponent, ref, PropType } from "@vue/composition-api";

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      required: true,
    },
    maxLength: Number,
    inputMode: String,
    validator: {
      type: Function as PropType<(value: string) => string>,
      default: (value: string) => value,
    },
  },
  emits: ["input", "click", "focus", "blur"],
  setup(props, context) {
    const input = ref();

    const inputHandler = (event: any) => {
      event.target.value = props.validator(event.target.value);
      context.emit("input", event.target.value);
    };

    return {
      input,
      inputHandler,
    };
  },
});
