














import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    color: String,
    width: {
      type: [String, Number],
      default: 24,
    },
    height: {
      type: [String, Number],
      default: 24,
    },
  },
});
